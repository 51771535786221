import { useTranslation } from "react-i18next";
import { SxProps, ToggleButton, ToggleButtonGroup } from "@mui/material";

type Props = {
  xPercents?: string;
  yPercents?: string;
}

const LanguageSelector: React.FC<Props> = ({ xPercents, yPercents }) => {
  const { i18n } = useTranslation();
  // lalala

  const positionAbsolute: SxProps = (xPercents && yPercents) ? {
    position: 'absolute',
    top: yPercents + '%',
    left: xPercents + '%'
  } : {};

  const onChangeLanguage = (
    event: React.MouseEvent<HTMLElement>,
    newLanguage: string,
  ) => {
    if (newLanguage !== null) {
      void i18n.changeLanguage(newLanguage);
    }
  };

  return (
    <ToggleButtonGroup
      sx={positionAbsolute}
      color="primary"
      value={i18n.language}
      exclusive
      onChange={onChangeLanguage}
    >
      <ToggleButton value="en-EN" sx={{ color: 'white' }}>En</ToggleButton>
      <ToggleButton value="pl-PL" sx={{ color: 'white' }}>Pl</ToggleButton>
    </ToggleButtonGroup>
  )
};

export default LanguageSelector;
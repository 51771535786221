import { Box } from "@mui/material";
import React from "react";
import Menu from "components/menu/Menu";
import Navbar from "components/navbar/Navbar";

const PageTemplate: React.FC = ({ children }) => (
  <Box sx={{ display: 'flex', height: '100vh' }}>
    <Menu />
    <Box sx={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
      <Navbar />
      {children}
    </Box>
  </Box>
)

export default PageTemplate;
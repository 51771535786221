import Dashboard from 'views/dashboard/Dashboard';
import Login from 'views/login/Login';
import Contact from 'views/contact/Contact';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import UsersBoard from 'views/users-board/UsersBoard';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

export const routes = [

  {
    name: 'Login',
    element: <Login />,
    path: '/login',
    permission: 'guest'
  },
  {
    name: 'Dashboard',
    element: <Dashboard />,
    path: '/',
    icon: <DashboardIcon />,
    permission: 'user'
  }, {
    name: 'Users',
    element: <UsersBoard />,
    path: '/users',
    icon: <SupervisedUserCircleIcon />,
    permission: 'user'
  },
  {
    name: 'Contact',
    element: <Contact />,
    path: '/Contact',
    icon: <ContactSupportIcon />,
    permission: 'user'
  }
]
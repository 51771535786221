import { DialogContent, DialogContentText } from "@mui/material";
import { deleteShareholders } from "services/shareholders.service";
import ActionModal from "components/modals/ActionModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  shareholders: string[];
  onSuccess: () => void;
  onError: () => void;
  onClose: () => void;
}

const DeleteModal: React.FC<Props> = ({ shareholders, onSuccess, onError, onClose }) => {
  const { t } = useTranslation('shareholderModal')
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = () => {
    setIsLoading(true);

    const uuids = {
      uuids: shareholders,
    }
    deleteShareholders(uuids)
      .then((res => {
        onSuccess();
      }))
      .catch((err) => {
        onError();
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const renderContent = () => {
    return (
      <DialogContent>
        <DialogContentText>
          {t('shareholderModal:question.delete')}
        </DialogContentText>
      </DialogContent>
    )
  }

  return <ActionModal
    submitBtnLabel={t('shareholderModal:btn.confirm')}
    loading={isLoading}
    content={renderContent()}
    onSubmit={onSubmit}
    onClose={onClose}
  />
};

export default DeleteModal;
type Header = {
  label: string;
  text: string;
}

export const shareholdersHead: Header[] = [
  {
    label: 'checkbox',
    text: "",
  },
  {
    label: 'name',
    text: "table.name",
  },
  {
    label: 'pesel',
    text: "table.pesel",
  },
  {
    label: 'addedDate',
    text: "table.date",
  },
  {
    label: 'status',
    text: "table.status",
  },
  {
    label: 'action',
    text: "",
  }
]
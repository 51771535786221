import { useState } from "react";
import { Table, TableRow, TableCell, TableBody, Checkbox, Button, Pagination, TableContainer } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "components/loader/Loader";
import { UserData } from "services/users.service";
import UpsertUserModal from "views/users-board/modals/UpsertUserModal";
import { usersHead } from "views/users-board/table/usersHead";
import TableHeader from "views/users-board/table/TableHeader";
import { useMessage } from "components/snackbar/useMessage";
import SnackbarComponent from 'components/snackbar/Snackbar';

type Props = {
  data: UserData[];
  isSelected: (email: string) => boolean;
  onItemSelectionChange: (email: string) => void;
  onModifyUser: () => void;
  isLoading: boolean;
}

type ModalName = 'editUser' | undefined;

const TableComponent: React.FC<Props> = ({ data, isSelected, onItemSelectionChange, isLoading, onModifyUser }) => {
  const [user, setUser] = useState<UserData>()
  const [openModal, setOpenModal] = useState<ModalName>(undefined)
  const [page, setPage] = useState<number>(1);
  const { message, setErrorMessage, setSuccessMessage, clearMessage } = useMessage();
  const rowsPerPage = 10;
  const { t } = useTranslation('userTable, snackbar')

  const renderModals = () => {

    switch (openModal) {
      case 'editUser':
        return <UpsertUserModal
          user={user}
          onSuccess={() => {
            setOpenModal(undefined);
            setSuccessMessage(t('snackbar:success.edit'))
            onModifyUser();
          }}
          onError={() => {
            setErrorMessage(t('snackbar:error.server'))
          }}
          onClose={() => {
            setOpenModal(undefined)
          }}
        />
      default:
        return;
    }

  }

  const handleClickEditBtn = (item: UserData) => {
    setUser(item);
    setOpenModal('editUser')
  }


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer sx={{ height: '100%', margin: '2rem 0 0' }}>
          <Table sx={{ width: '100%' }}>

            <TableHeader def={usersHead} />
            <TableBody>
              {data
                .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                .map((item) => {
                  const isItemSelected = isSelected(item.email);

                  return (
                    <TableRow
                      hover
                      key={item.email}
                      selected={isItemSelected}
                      sx={{ borderBottom: '2px solid rgb(20, 30, 56)' }}
                    >

                      <TableCell component="th" scope="row" padding="checkbox">
                        <Checkbox
                          color="primary"
                          onClick={() => onItemSelectionChange(item.email)}
                        />
                      </TableCell>
                      <TableCell>{item.firstName} {item.lastName}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell padding="checkbox"><Button onClick={() => handleClickEditBtn(item)}>{t('userTable:btn.edit')}</Button></TableCell>

                    </TableRow>
                  )
                })}

            </TableBody>

          </Table>
        </TableContainer>

      )}

      <Pagination
        count={Math.ceil(data.length / rowsPerPage)}
        page={page}
        onChange={(event, newPage) => setPage(newPage)}
      />

      <SnackbarComponent message={message} onClose={clearMessage} />
      {renderModals()}

    </>

  )
}

export default TableComponent;

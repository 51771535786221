import { Routes, Route } from "react-router-dom";
import Auth from 'core/Auth';
import { routes } from 'routes/routes';
import PageTemplate from "templates/PageTemplate";

export const Router: React.FC = () => (
  <Routes>
    {routes.map((route, i) => {
      if (route.permission === 'guest') {
        return <Route path={route.path} element={route.element} key={i} />
      } else if (route.permission === 'user') {
        return <Route path={route.path} element={
          <Auth>
            <PageTemplate>
              {route.element}
            </PageTemplate>
          </Auth>
        }
          key={i} />
      } else return null;
    })}
  </Routes>
);
import { AxiosResponse } from 'axios';
import { axiosInstanceWithAuthorization, defaultAxiosInstance } from 'services/axiosInstances';

type UserCredentials = {
  email: string;
  password: string;
}

export type UserData = {
  email: string;
  firstName: string;
  lastName: string;
}

export type PostedUser = {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
}

export type DeleteResponse = {
  success: boolean
}

export const login = (user: UserCredentials): Promise<AxiosResponse<string>> => {
  return defaultAxiosInstance.post(`/users/login`, user)
}
export const getUsers = (): Promise<AxiosResponse<UserData[]>> => {
  return axiosInstanceWithAuthorization.get('/users')
}
export const addUser = (user: PostedUser): Promise<AxiosResponse<UserData>> => {
  return axiosInstanceWithAuthorization.post('/users', user)
}
export const updateUser = (email: string, user: PostedUser): Promise<AxiosResponse<UserData>> => {
  return axiosInstanceWithAuthorization.put(`/users/${email}`, user)
}
export const deleteUsers = (emails: string[]): Promise<AxiosResponse<DeleteResponse>> => {
  return axiosInstanceWithAuthorization.post('/users/massDelete', { emails })
}
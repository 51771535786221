import { DialogContent, DialogContentText } from "@mui/material";
import { suspendShareholders } from 'services/shareholders.service';
import ActionModal from "components/modals/ActionModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  shareholders: Array<string>;
  onSuccess: () => void;
  onError: () => void;
  onClose: () => void;
}

const SuspendModal: React.FC<Props> = ({ shareholders, onSuccess, onError, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation('shareholderModal')

  const onSubmit = () => {

    setIsLoading(true);

    const uuids = {
      uuids: shareholders,
    }

    suspendShareholders(uuids)
      .then((res) => {
        onSuccess();
      })
      .catch((err) => {
        onError();
      })
      .finally(() => {
        setIsLoading(false);
      })

  }

  const renderContent = () => {
    return (
      <DialogContent>
        <DialogContentText>
          {t('shareholderModal:question.suspend')}
        </DialogContentText>
      </DialogContent>
    )
  }

  return <ActionModal
    submitBtnLabel={t('shareholderModal:btn.confirm')}
    loading={isLoading}
    content={renderContent()}
    onSubmit={onSubmit}
    onClose={onClose}
  />
};

export default SuspendModal;
import { AccountCircle } from "@mui/icons-material";
import { Box, DialogContent, DialogTitle, FormControlLabel, Grid, InputAdornment, Switch, TextField } from "@mui/material";
import LooksOneIcon from '@mui/icons-material/LooksOne';
import GroupIcon from '@mui/icons-material/Group';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { useTranslation } from "react-i18next";
import ActionModal from "components/modals/ActionModal";
import { addShareholders, Shareholder, updateShareholders } from 'services/shareholders.service';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

type Props = {
  shareholder?: Shareholder;
  onSuccess: () => void;
  onError: () => void;
  onClose: () => void;
}

export type FormValues = {
  firstName: string;
  lastName: string;
  pesel: string;
  active: boolean;
  chainAddress: string;
}

const emptyInitialValues: FormValues = {
  firstName: '',
  lastName: '',
  pesel: '',
  chainAddress: '',
  active: true
}

export const getInitialValues = (shareholder: Shareholder | undefined): FormValues => {
  if (shareholder === undefined) {
    return emptyInitialValues;
  } else {
    return {
      firstName: shareholder.firstName,
      lastName: shareholder.lastName,
      pesel: shareholder.pesel,
      active: shareholder.active,
      chainAddress: shareholder.chainAddress
    }
  }
}

const ShareholderDetailsModal: React.FC<Props> = ({ shareholder, onSuccess, onError, onClose }) => {
  const { t } = useTranslation('shareholderModal');

  const edit = shareholder !== undefined;

  const ShareholderSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t('shareholderModal:validation.required')),
    lastName: Yup.string()
      .required(t('shareholderModal:validation.required')),
    pesel: Yup.string()
      .matches(/^(?=.{11,12})([0-9])*$/, t('shareholderModal:validation.pesel.length'))
      .required(t('shareholderModal:validation.required')),
    chainAddress: Yup.string()
      .matches(/^0x[a-fA-F0-9]{40}$/g, t('shareholderModal:validation.chainAddress.match'))
  })

  const formik = useFormik({
    initialValues: getInitialValues(shareholder),
    validationSchema: ShareholderSchema,
    onSubmit: (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      if (shareholder) {
        updateShareholders(values, shareholder.uuid)
          .then((res) => {
            onSuccess();
          })
          .catch(err => {
            onError();
          })
          .finally(() => {
            setSubmitting(false);
          })
      } else {
        addShareholders(values)
          .then((res) => {
            onSuccess();
          })
          .catch(err => {
            onError();
          })
          .finally(() => {
            setSubmitting(false);
          })
      }
    }
  })

  const renderHeader = () => {
    return (
      <DialogTitle>
        {edit ? t('shareholderModal:header.editTitle') : t('shareholderModal:header.addTitle')}
      </DialogTitle>
    )
  }

  const renderContent = () => {
    return (
      <DialogContent>

        <Box component='form' >

          <Grid container spacing={4} alignItems="center">

            <Grid item xs={6}>
              <TextField
                label={t("shareholderModal:label.firstName")}
                data-testid="dashboard.textInputFirstName"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle color="primary" />
                    </InputAdornment>
                  ),
                }}
                error={formik.errors.firstName ? true : false}
                type="text"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                helperText={
                  formik.errors.firstName && formik.touched.firstName && formik.errors.firstName
                }
                variant="outlined" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t("shareholderModal:label.lastName")}
                data-testid="dashboard.textInputLastName"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GroupIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                error={formik.errors.lastName ? true : false}
                type="text"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                helperText={
                  formik.errors.lastName && formik.touched.lastName && formik.errors.lastName
                }
                variant="outlined" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t("shareholderModal:label.pesel")}
                data-testid="dashboard.textInputPesel"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LooksOneIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                error={formik.errors.pesel ? true : false}
                type="text"
                name="pesel"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pesel}
                helperText={
                  formik.errors.pesel && formik.touched.pesel && formik.errors.pesel
                }
                variant="outlined" />
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                labelPlacement="top"
                data-testid="dashboard.switchInput"
                control={<Switch
                  name='active'
                  checked={formik.values.active}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange} />}
                label={t<string>("label.active")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("shareholderModal:label.chainAddress")}
                data-testid="dashboard.textInputChainAddress"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WidgetsIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                error={formik.errors.chainAddress ? true : false}
                type="text"
                name="chainAddress"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.chainAddress}
                helperText={
                  formik.errors.chainAddress
                  && formik.touched.chainAddress
                  && formik.errors.chainAddress
                }
                variant="outlined" />
            </Grid>

          </Grid>

        </Box>

      </DialogContent>
    )
  }

  return <ActionModal
    submitBtnLabel={edit ? t('shareholderModal:btn.edit') : t('shareholderModal:btn.add')}
    loading={formik.isSubmitting}
    header={renderHeader()}
    content={renderContent()}
    onSubmit={formik.handleSubmit}
    onClose={onClose}
  />
};

export default ShareholderDetailsModal;
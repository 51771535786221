import { useState } from "react";
import { Box, Avatar, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAuthContext from "core/contexts/useAuthContext";
import jwtDecode, { JwtPayload } from "jwt-decode";

interface TokenTypes extends JwtPayload {
  firstName: string;
  lastName: string;
  email: string;
}

const Navbar: React.FC = () => {
  const { t } = useTranslation('navbar');
  const { resetJWTToken } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const token = localStorage.getItem('token') ?? "";
  const decodedToken = jwtDecode<TokenTypes>(token);
  const { firstName, lastName } = decodedToken;

  const handleClickLogout = () => {
    resetJWTToken();
  }
  function getInitial(name: string) {
    return name[0] || ''
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "0 2rem", width: "100%", boxShadow: '0 0 20px 15px rgb(71, 122, 255)', backgroundColor: 'rgb(20, 30, 56)' }}>

      <Box sx={{ display: "flex", alignItems: 'end' }}>
        <Typography sx={{ fontWeight: 'bold', color: 'white' }} variant="h1" component="h1">BOA </Typography>
        <Typography sx={{ margin: '0 0 1rem .5rem', color: 'white' }} variant="h5" component="h5">Biuro Maklerskie Bank 7bulls Sp. z.o.o</Typography>
      </Box>

      <Box sx={{ display: "flex", gap: 2, alignItems: 'end', margin: '1rem 0' }}>
        <Box sx={{ margin: 'auto auto' }} >
          <Button
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(event) => setAnchorEl(event.currentTarget)}>
            <Avatar>{getInitial(firstName) + getInitial(lastName)}</Avatar>
          </Button>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClickLogout}>{t('navbar:btn.Logout')}</MenuItem>
        </Menu>
      </Box>

    </Box>
  )
}

export default Navbar; 
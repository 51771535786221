type Header = {
  label: string;
  text: string;
}

export const usersHead: Header[] = [
  {
    label: 'checkbox',
    text: "",
  },
  {
    label: 'name',
    text: "table.name",
  },
  {
    label: 'email',
    text: "table.email",
  },
  {
    label: 'action',
    text: "table.action",
  }
]
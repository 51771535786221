import { Box, CircularProgress } from "@mui/material";

const Loader: React.FC = () => {

  return (
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <CircularProgress size='4rem' />
    </Box>
  )
}

export default Loader;
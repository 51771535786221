import { Box, Button } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import DeleteModal from "views/users-board/modals/DeleteModal";
import SnackbarComponent from "components/snackbar/Snackbar";
import Table from "views/users-board/table/Table";
import { getUsers, UserData } from "services/users.service";
import UpsertUserModal from "views/users-board/modals/UpsertUserModal";
import { useMessage } from "components/snackbar/useMessage";

export type ModalName = 'addUser' | 'deleteUsers' | undefined;

const UsersBoard: React.FC = () => {
  const [users, setUsers] = useState<UserData[]>([])
  const [openModal, setOpenModal] = useState<ModalName>(undefined);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { message, setErrorMessage, setSuccessMessage, clearMessage } = useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation('userTable');

  const fetchUsers = useCallback(() => {
    getUsers()
      .then(res => {
        setUsers(res.data);
      })
      .catch(err => {
        setErrorMessage(t('snackbar:error.server'));
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [setErrorMessage, t])

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers])

  const renderModals = () => {

    switch (openModal) {
      case 'addUser':
        return <UpsertUserModal
          onSuccess={() => {
            fetchUsers();
            setSuccessMessage(t('snackbar:success.add'))
            setOpenModal(undefined);
          }}
          onError={() => {
            setErrorMessage(t('snackbar:error.server'))
          }}
          onClose={() => {
            setOpenModal(undefined)
          }}
        />
      case 'deleteUsers':
        return <DeleteModal
          users={selectedItems}
          onSuccess={() => {
            fetchUsers();
            setSuccessMessage(t('snackbar:success.delete'))
            setOpenModal(undefined);
          }}
          onError={() => {
            setErrorMessage(t('snackbar:error.server'))
          }}
          onClose={() => {
            setOpenModal(undefined)
          }}
        />
      default:
        return
    }
  }

  const handleItemSelectionChange = (email: string) => {
    const selectedIndex = selectedItems.indexOf(email);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1),
      );
    }

    setSelectedItems(newSelected);
  };

  const isSelected = (email: string) => selectedItems.indexOf(email) !== -1;

  return (
    <>
      <Box sx={{ flexGrow: '1', padding: "3rem 5rem 1rem", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'auto' }}>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" onClick={() => setOpenModal('addUser')}>{t('userBoard:btn.addUser')}</Button>
          <Button variant="contained" disabled={selectedItems.length ? false : true} onClick={() => setOpenModal('deleteUsers')}>{t('userBoard:btn.delete')} </Button>
        </Box>

        <Table
          data={users}
          onModifyUser={fetchUsers}
          isLoading={isLoading}
          isSelected={isSelected}
          onItemSelectionChange={handleItemSelectionChange}
        />

      </Box>

      <SnackbarComponent message={message} onClose={clearMessage} />
      {renderModals()}
    </>
  )
};

export default UsersBoard;
import { useLocation, Navigate } from "react-router-dom";
import { useEffect } from "react";
import useAuthContext from "core/contexts/useAuthContext";

const Auth: React.FC = ({ children }) => {
  const location = useLocation();
  const { token, setJWTToken, resetJWTToken } = useAuthContext();
  const tokenFromStorage = localStorage.getItem('token');

  useEffect(() => {
    if (tokenFromStorage) {
      setJWTToken(tokenFromStorage);
    } else {
      resetJWTToken()
    }
  }, [token, setJWTToken, resetJWTToken, tokenFromStorage]);

  return tokenFromStorage ? <>{children}</> : <Navigate to="/login" state={{ from: location }} />;
}

export default Auth;
import React from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import PasswordIcon from '@mui/icons-material/Password';
import AccountCircle from '@mui/icons-material/AccountCircle';
import useAuthContext from "core/contexts/useAuthContext";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { login } from "services/users.service";
import bgc from 'assets/images/login-bgc.jpg';
import LanguageSelector from "components/language-selector/LanguageSelector";
import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useMessage } from "components/snackbar/useMessage";
import SnackbarComponent from "components/snackbar/Snackbar";

type FormValues = {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { message, setErrorMessage, clearMessage } = useMessage();
  const { t } = useTranslation('login, snackbar')

  const { setJWTToken } = useAuthContext();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('login:validation.email.structure')).required(t('login:validation.required')),
    password: Yup.string()
      .min(6, t('login:validation.password.length'))
      .required('Required')
  })

  return (
    <Box sx={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${bgc})`,
    }}>

      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
          login(values)
            .then(res => {
              setJWTToken(res.data);
              navigate('/');
            })
            .catch(err => {
              setErrorMessage(t('snackbar:error.badEmailOrPsw'))
            })
            .finally(() => {
              setSubmitting(false);
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }: FormikProps<FormValues>) => (
          <Box component="form" sx={{
            padding: '5rem',
            boxShadow: '0 0 20px 10px rgb(63, 80, 181)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgb(230, 230, 230)',
            borderRadius: '20px',
            position: 'relative'
          }}
            onSubmit={handleSubmit}
          >

            <AccountCircle color="primary" sx={{ fontSize: '5rem' }} />
            <Typography variant="h3" sx={{
              margin: '1rem 0 4rem',
            }}>{t('login:title')}</Typography>

            <Box>

              <TextField
                error={errors.email ? true : false}
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                label={t("login:label.email")}
                helperText={
                  errors.email && touched.email && errors.email
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle color="primary" />
                    </InputAdornment>
                  ),
                }}
                variant="outlined" />
              <TextField
                sx={{
                  margin: '0 0 0 1rem',
                }}
                error={errors.password ? true : false}
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                label={t("login:label.psw")}
                helperText={
                  errors.password && touched.password && errors.password
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                variant="outlined" />

            </Box>

            <LoadingButton
              variant="contained"
              sx={{
                margin: '2rem 0 1rem',
              }}
              loading={isSubmitting}
              type='submit'>
              {t('login:btnSignIn')}
            </LoadingButton>

            <Box sx={{ width: '100%' }}>

              <Typography variant="body1" color="primary" sx={{ textDecoration: "underline", cursor: 'pointer' }}>
                {t('login:pPswForgot')}
              </Typography>

            </Box>

            <LanguageSelector xPercents='80' yPercents="5" />

          </Box>
        )}
      </Formik>

      <SnackbarComponent message={message} onClose={clearMessage} />

    </Box >
  )
}

export default Login;

import { DialogContent, DialogContentText } from "@mui/material";
import { deleteUsers } from "services/users.service";
import ActionModal from "components/modals/ActionModal";
import { useTranslation } from "react-i18next";
import { useState } from "react";

type Props = {
  users: string[];
  onSuccess: () => void;
  onError: () => void;
  onClose: () => void;
}

const DeleteModal: React.FC<Props> = ({ users, onSuccess, onError, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation('userModal');

  const onSubmit = () => {
    setIsLoading(true);

    deleteUsers(users)
      .then(res => {
        onSuccess();
      })
      .catch((err) => {
        onError()
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const renderContent = () => {
    return (
      <DialogContent>
        <DialogContentText>
          {t('userModal:question.delete')}
        </DialogContentText>
      </DialogContent>
    )
  }

  return <ActionModal
    loading={isLoading}
    submitBtnLabel={t('userModal:btn.confirm')}
    content={renderContent()}
    onSubmit={onSubmit}
    onClose={onClose}
  />
}

export default DeleteModal;
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import noMatchTranslationEn from 'views/404/translations/en.json'
import noMatchTranslationPl from 'views/404/translations/pl.json'
import dashboardTranslationEn from 'views/dashboard/translations/translationEn.json';
import dashboardTranslationPl from 'views/dashboard/translations/translationPl.json';
import shareholderTableTranslationEn from "views/dashboard/table/translations/translationEn.json"
import shareholderTableTranslationPl from "views/dashboard/table/translations/translationPl.json"
import shareholderModalTranslationEn from 'views/dashboard/modals/translations/translationEn.json';
import shareholderModalTranslationPl from 'views/dashboard/modals/translations/translationPl.json';
import loginTranslationPl from 'views/login/translations/translationPl.json';
import loginTranslationEn from 'views/login/translations/translationEn.json';
import userBoardTranslationEn from 'views/users-board/translations/translationEn.json';
import userBoardTranslationPl from 'views/users-board/translations/translationPl.json';
import userModalTranslationEn from 'views/users-board/modals/translations/translationEn.json'
import userModalTranslationPl from 'views/users-board/modals/translations/translationPl.json'
import userTableTranslationEn from "views/users-board/table/translations/translationEn.json"
import userTableTranslationPl from "views/users-board/table/translations/translationPl.json";
import navbarTranslationPl from "components/navbar/translations/translationPl.json";
import navbarTranslationEn from "components/navbar/translations/translationEn.json";
import modalTranslationEn from 'components/modals/translations/translationEn.json';
import modalTranslationPl from 'components/modals/translations/translationPl.json';
import snackbarTranslationPl from 'components/snackbar/translations/translationPl.json';
import snackbarTranslationEn from 'components/snackbar/translations/translationEn.json';
import web3ConnectTranslationEn from 'components/web3-connect/translations/translationEn.json'
import web3ConnectTranslationPl from 'components/web3-connect/translations/translationPl.json'

const resources = {
  "en-EN": {
    noMatch: noMatchTranslationEn,
    dashboard: dashboardTranslationEn,
    shareholderTable: shareholderTableTranslationEn,
    shareholderModal: shareholderModalTranslationEn,
    login: loginTranslationEn,
    userBoard: userBoardTranslationEn,
    userTable: userTableTranslationEn,
    userModal: userModalTranslationEn,
    navbar: navbarTranslationEn,
    modal: modalTranslationEn,
    snackbar: snackbarTranslationEn,
    web3Connect: web3ConnectTranslationEn,
  },
  "pl-PL": {
    noMatch: noMatchTranslationPl,
    dashboard: dashboardTranslationPl,
    shareholderTable: shareholderTableTranslationPl,
    shareholderModal: shareholderModalTranslationPl,
    login: loginTranslationPl,
    userBoard: userBoardTranslationPl,
    userTable: userTableTranslationPl,
    userModal: userModalTranslationPl,
    navbar: navbarTranslationPl,
    modal: modalTranslationPl,
    snackbar: snackbarTranslationPl,
    web3Connect: web3ConnectTranslationPl,
  }
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false
    },
    fallbackLng: "pl-PL"
  })

export default i18next;
import { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, Box } from "@mui/material";
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import { routes } from 'routes/routes';
import LanguageSelector from 'components/language-selector/LanguageSelector';

type MenuItem = {
  name: string;
  element: JSX.Element;
  path: string;
  permission: string;
  icon?: JSX.Element;
}

const Menu: React.FC = () => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState<string>(location.pathname);
  const [isIconsMenu, setIconsMenu] = useState<boolean>(false);

  const renderMenuItem = (item: MenuItem) => {
    if (item.permission === 'user') {
      return (
        <ListItem
          button
          key={item.name}
          selected={selectedItem === item.path}
          onClick={() => setSelectedItem(item.path)}
          component={Link}
          to={item.path}
          sx={{
            ':hover': {
              backgroundColor: 'rgb(40, 98, 250)'
            }
          }}>
          {isIconsMenu ? (
            <ListItemIcon sx={{ textAlign: 'center', color: "white", padding: "2rem 2rem" }} >
              {item.icon}
            </ListItemIcon>
          ) : (
            <ListItemText disableTypography primary={item.name} sx={{ textAlign: 'center', color: "white", padding: "2rem 3rem" }} />
          )}
        </ListItem>
      )
    } else return null
  }

  return (
    <>
      <List disablePadding sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%', backgroundColor: 'rgb(20, 30, 56)' }}>
        <ListItem button sx={{ padding: "3rem 0", display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => setIconsMenu(!isIconsMenu)}>
          {isIconsMenu ? (
            <AppsIcon sx={{ fontSize: '2.5rem', color: 'white' }} />
          ) : (
            <ListIcon sx={{ fontSize: '2.5rem', color: 'white' }} />
          )}
        </ListItem>
        {
          routes.map((route, index) => {
            return renderMenuItem(route)
          })
        }
        <Box sx={{ margin: 'auto auto 2rem' }}>
          <LanguageSelector />
        </Box>
      </List >
    </>
  )
}

export default Menu;
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from 'routes/Router';
import { CssBaseline } from '@mui/material';
import { AuthProvider } from 'core/contexts/AuthContext';
import { Mainnet, DAppProvider, Config } from '@usedapp/core'
import { RPC_URL_1 } from 'const/connectors/connectors';

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: RPC_URL_1
  }
}

const Root: React.FC = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <DAppProvider config={config}>
        <BrowserRouter>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </BrowserRouter>
      </DAppProvider>
    </React.Fragment>
  );
}

export default Root;

import { Box, Button } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import DeleteModal from "views/dashboard/modals/DeleteModal";
import SuspendModal from "views/dashboard/modals/SuspendModal";
import Table from "views/dashboard/table/Table";
import { getShareholders, Shareholder } from "services/shareholders.service";
import ShareholderDetailsModal from "views/dashboard/modals/ShareholderDetailsModal";
import SnackbarComponent from "components/snackbar/Snackbar";
import { useMessage } from "components/snackbar/useMessage";

export type ModalName = 'addShareholder' | 'suspendShareholders' | 'deleteShareholders' | undefined;

const Dashboard: React.FC = () => {
  const [shareholders, setShareholders] = useState<Array<Shareholder>>([])
  const [openModal, setOpenModal] = useState<ModalName>(undefined)
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { message, setErrorMessage, setSuccessMessage, clearMessage } = useMessage();
  const { t } = useTranslation('dashboard, shareholderModal, snackbar')

  const fetchShareholders = useCallback(() => {
    getShareholders()
      .then(res => {
        setShareholders(res.data);
      })
      .catch(err => {
        setErrorMessage(t('snackbar:error.server'))
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [setErrorMessage, t])

  useEffect(() => {
    fetchShareholders();
  }, [fetchShareholders])

  const renderModals = () => {

    switch (openModal) {
      case 'addShareholder':
        return <ShareholderDetailsModal
          onSuccess={() => {
            fetchShareholders();
            setSuccessMessage(t('snackbar:success.add'))
            setOpenModal(undefined);
          }}
          onError={() => {
            setErrorMessage(t('snackbar:error.server'))
          }}
          onClose={() => {
            setOpenModal(undefined)
          }}
        />
      case 'deleteShareholders':
        return <DeleteModal
          shareholders={selectedItems}
          onSuccess={() => {
            fetchShareholders();
            setSuccessMessage(t('snackbar:success.delete'))
            setOpenModal(undefined);
          }}
          onError={() => {
            setErrorMessage(t('snackbar:error.server'))
          }}
          onClose={() => {
            setOpenModal(undefined)
          }}
        />
      case 'suspendShareholders':
        return <SuspendModal
          shareholders={selectedItems}
          onSuccess={() => {
            fetchShareholders();
            setSuccessMessage(t('snackbar:success.suspend'))
            setOpenModal(undefined);
          }}
          onError={() => {
            setErrorMessage(t('snackbar:error.server'))
          }}
          onClose={() => {
            setOpenModal(undefined)
          }}
        />
      default:
        return
    }

  }

  const handleItemSelectionChange = (uuids: string) => {
    const selectedIndex = selectedItems.indexOf(uuids);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, uuids);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1),
      );
    }

    setSelectedItems(newSelected);
  };

  const isSelected = (uuids: string) => selectedItems.indexOf(uuids) !== -1;

  return (
    <>
      <Box sx={{ flexGrow: '1', padding: "3rem 5rem 1rem", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'auto' }}>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" onClick={() => setOpenModal('addShareholder')}>{t('dashboard:btn.addShareholder')}</Button>
          <Box>
            <Button sx={{ margin: '0 1rem 0 0' }} variant="contained" disabled={selectedItems.length ? false : true} onClick={() => setOpenModal('suspendShareholders')}>{t('dashboard:btn.suspend')}</Button>
            <Button variant="contained" disabled={selectedItems.length ? false : true} onClick={() => setOpenModal('deleteShareholders')}>{t('dashboard:btn.delete')} </Button>
          </Box>
        </Box>

        <Table data={shareholders} onModifyShareholder={fetchShareholders} isLoading={isLoading} isSelected={isSelected} onItemSelectionChange={handleItemSelectionChange} />

      </Box>

      <SnackbarComponent message={message} onClose={clearMessage} />
      {renderModals()}
    </>
  )
};

export default Dashboard;

import { useState } from "react";
import { AccountCircle } from "@mui/icons-material";
import { Box, Button, DialogContent, DialogTitle, Grid, InputAdornment, TextField } from "@mui/material";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PasswordIcon from '@mui/icons-material/Password';
import { useTranslation } from "react-i18next";
import ActionModal from "components/modals/ActionModal";
import { addUser, UserData, updateUser, PostedUser } from 'services/users.service';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

type Props = {
  user?: UserData;
  onSuccess: () => void;
  onError: () => void;
  onClose: () => void;
}

export type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const emptyInitialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: ''
}

export const getInitialValues = (user: UserData | undefined): FormValues => {
  if (user === undefined) {
    return emptyInitialValues;
  } else {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: 'password'
    }
  }
}


const UpsertUserModal: React.FC<Props> = ({ user, onSuccess, onError, onClose }) => {
  const [isPasswordForChange, setIsPasswordForChange] = useState<boolean>(false)
  const { t } = useTranslation('userModal');

  const edit = user !== undefined;

  const UserSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[aA-zZ\s]*$/, t('userModal:validation.firstName.letters'))
      .required(t('userModal:validation.required')),
    lastName: Yup.string()
      .matches(/^[aA-zZ\s]*$/, t('userModal:validation.lastName.letters'))
      .required(t('userModal:validation.required')),
    email: Yup.string().email(t('userModal:validation.email.structure')).required(t('userModal:validation.required')),
    password: Yup.string()
      .min(6, t('userModal:validation.password.length'))
      .required(t('userModal:validation.required'))
  })

  const formik = useFormik({
    initialValues: getInitialValues(user),
    validationSchema: UserSchema,
    onSubmit: (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      let newUserData: PostedUser
      if (isPasswordForChange) {
        newUserData = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password
        }
      } else {
        newUserData = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email
        }
      }

      if (edit) {
        updateUser(
          `${user.email}`,
          newUserData
        )
          .then((res) => {
            onSuccess();
          })
          .catch(err => {
            onError();
          })
          .finally(() => {
            setSubmitting(false);
          })
      } else {
        addUser({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password
        })
          .then((res) => {
            onSuccess();
          })
          .catch(err => {
            onError();
          })
          .finally(() => {
            setSubmitting(false);
          })
      }
    }
  })

  const renderHeader = () => {
    return (
      <DialogTitle>
        {edit ? t('userModal:header.editTitle') : t('userModal:header.addTitle')}
      </DialogTitle>
    )
  }

  const renderContent = () => {
    return (
      <DialogContent>
        <Box component='form' >
          <Grid container spacing={4} alignItems="center">

            <Grid item xs={6}>
              <TextField
                label={t("userModal:label.firstName")}
                data-testid="userModal.inputFirstName"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle color="primary" />
                    </InputAdornment>
                  ),
                }}
                error={formik.errors.firstName ? true : false}
                type="text"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                helperText={
                  formik.errors.firstName && formik.touched.firstName && formik.errors.firstName
                }
                variant="outlined" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t("userModal:label.lastName")}
                data-testid="userModal.inputLastName"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle color="primary" />
                    </InputAdornment>
                  ),
                }}
                error={formik.errors.lastName ? true : false}
                type="text"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                helperText={
                  formik.errors.lastName && formik.touched.lastName && formik.errors.lastName
                }
                variant="outlined" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t("userModal:label.email")}
                data-testid="userModal.inputEmail"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                error={formik.errors.email ? true : false}
                type="text"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={
                  formik.errors.email && formik.touched.email && formik.errors.email
                }
                variant="outlined" />
            </Grid>

            <Grid item xs={6}>
              {!isPasswordForChange && edit ?
                <Button
                  variant="contained"
                  onClick={() => { setIsPasswordForChange(true) }}
                  data-testid="userModal.passwordBtn"
                >
                  {t('userModal:btn.changePassword')}
                </Button>
                :
                <TextField
                  label={t("userModal:label.password")}
                  data-testid="userModal.inputPassword"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  error={formik.errors.password ? true : false}
                  type="text"
                  name="password"
                  role="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  helperText={
                    formik.errors.password && formik.touched.password && formik.errors.password
                  }
                  variant="outlined" />
              }
            </Grid>

          </Grid>
        </Box>
      </DialogContent>
    )
  }

  return <ActionModal
    loading={formik.isSubmitting}
    submitBtnLabel={edit ? t('userModal:btn.edit') : t('userModal:btn.add')}
    header={renderHeader()}
    content={renderContent()}
    onSubmit={formik.handleSubmit}
    onClose={onClose} />
};

export default UpsertUserModal;
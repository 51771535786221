import { AxiosResponse } from 'axios';
import { axiosInstanceWithAuthorization } from 'services/axiosInstances';

export type Shareholder = {
  uuid: string;
  firstName: string;
  lastName: string;
  pesel: string;
  createdAt: string;
  active: boolean;
  chainAddress: string;
}

type PostedShareholder = {
  firstName: string;
  lastName: string;
  pesel: string;
  active: boolean;
  chainAddress: string;
}

type UUID = {
  uuids: Array<string>;
}
export type SuccessResponse = {
  success: boolean
}

export const getShareholders = (): Promise<AxiosResponse<Shareholder[]>> => {
  return axiosInstanceWithAuthorization.get(`/shareholders`);
}
export const addShareholders = (shareholder: PostedShareholder): Promise<AxiosResponse<Shareholder>> => {
  return axiosInstanceWithAuthorization.post('/shareholders', shareholder)
}
export const suspendShareholders = (uuid: UUID): Promise<AxiosResponse<SuccessResponse>> => {
  return axiosInstanceWithAuthorization.post('/shareholders/massSuspend', uuid)
}
export const updateShareholders = (shareholder: PostedShareholder, id: string): Promise<AxiosResponse<SuccessResponse>> => {
  return axiosInstanceWithAuthorization.put(`/shareholders/${id}`, shareholder)
}
export const deleteShareholders = (uuids: UUID): Promise<AxiosResponse<SuccessResponse>> => {
  return axiosInstanceWithAuthorization.post('/shareholders/massDelete', uuids)
}
import { useState } from "react";
import { Table, TableRow, TableCell, TableBody, Checkbox, Button, Pagination, TableContainer } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableHeader from "views/dashboard/table/TableHeader";
import Loader from "components/loader/Loader";
import SnackbarComponent from "components/snackbar/Snackbar";
import { useMessage } from "components/snackbar/useMessage";
import { Shareholder } from "services/shareholders.service";
import ShareholderDetailsModal from "views/dashboard/modals/ShareholderDetailsModal";
import { shareholdersHead } from "views/dashboard/shareholdersHead";


type Props = {
  data: Array<Shareholder>;
  isSelected: (uuid: string) => boolean;
  onItemSelectionChange: (uuid: string) => void;
  onModifyShareholder: () => void;
  isLoading: boolean;
}

type ModalName = 'editShareholder' | undefined;

const TableComponent: React.FC<Props> = ({ data, isSelected, onItemSelectionChange, isLoading, onModifyShareholder }) => {
  const [chosenShareholder, setChosenShareholder] = useState<Shareholder>();
  const [openModal, setOpenModal] = useState<ModalName>(undefined);
  const [page, setPage] = useState<number>(1);
  const rowsPerPage = 10;
  const { message, setErrorMessage, setSuccessMessage, clearMessage } = useMessage();
  const { t } = useTranslation('shareholderTable, snackbar')

  const renderModals = () => {

    switch (openModal) {
      case 'editShareholder':
        return <ShareholderDetailsModal
          shareholder={chosenShareholder}
          onSuccess={() => {
            setOpenModal(undefined);
            setSuccessMessage(t('snackbar:success.edit'))
            onModifyShareholder();
          }}
          onError={() => {
            setErrorMessage(t('snackbar:error.server'))
          }}
          onClose={() => {
            setOpenModal(undefined)
          }}
        />
      default:
        return;
    }

  }

  const handleClickEditBtn = (item: Shareholder) => {
    setChosenShareholder(item);
    setOpenModal('editShareholder')
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer sx={{ height: '100%', margin: '2rem 0 0' }}>
          <Table sx={{ width: '100%' }}>

            <TableHeader def={shareholdersHead} />
            <TableBody>

              {data
                .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                .map((item) => {
                  const isItemSelected = isSelected(item.uuid);

                  return (
                    <TableRow
                      hover
                      key={item.uuid}
                      selected={isItemSelected}
                      sx={{ borderBottom: '2px solid rgb(20, 30, 56)' }}
                    >

                      <TableCell component="th" scope="row" padding="checkbox">
                        <Checkbox
                          color="primary"
                          onClick={() => onItemSelectionChange(item.uuid)}
                        />
                      </TableCell>
                      <TableCell>{item.firstName} {item.lastName}</TableCell>
                      <TableCell>{item.pesel}</TableCell>
                      <TableCell>{item.createdAt}</TableCell>
                      <TableCell>{item.active ? t('shareholderTable:cells.active') : t('shareholderTable:cells.inactive')}</TableCell>
                      <TableCell padding="checkbox"><Button onClick={() => handleClickEditBtn(item)}>{t('shareholderTable:btn.edit')}</Button></TableCell>

                    </TableRow>
                  )
                })}

            </TableBody>

          </Table>
        </TableContainer>

      )}

      <Pagination
        count={Math.ceil(data.length / rowsPerPage)}
        page={page}
        onChange={(event, newPage) => setPage(newPage)}
      />

      <SnackbarComponent message={message} onClose={clearMessage} />
      {renderModals()}

    </>

  )
}

export default TableComponent;
